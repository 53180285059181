<template>
	<div class="job-card">
        <img :alt="data.job.title" :src="data.job.image" />
        <div class="body">
            <div class="features">
                <span>
                    {{ data.job.department }}
                </span>
                <span>
                    {{ data.job.location }}
                </span>
                <span>
                    {{ data.job.role }}
                </span>
            </div>
            <h5>
                {{ data.job.title }}
            </h5>
            <span class="description" v-html="data.job.description"></span>
            <p>
                {{ data.languageLabel }}: <span>{{ data.job.language }}</span>
            </p>
            <Buttons :data="[{buttonText: data.detailsLabel, action: fn}]" />
        </div>
    </div>
</template>

<script>
	
export default {
    name: "CSUtilsJobCard",
    props: {
        data: Object,
    },
    components: {
        Buttons: () => import("./CSUtilsButtons.vue"),
    },
    methods:{
        fn(){
            this.data.fn(this.data)
        }
    }
};
</script>

<style lang="scss" scoped>
    .job-card {
        margin-top: 15px * 2;
        border-radius: 15px;
        border: 1px solid rgba(0,0,0,0.4);

        img {
            width: 100%;
            object-fit: cover;
            object-position: center;
            height: 150px;
            border-radius: 15px 15px 0px 0px;
        }

        .body {
            padding: 15px 15px 15px * 2 15px;
        }

        .features {
            display: flex;
            flex-wrap: wrap;
            margin-left: 15px / 2 * -1;
            margin-right: 15px / 2 * -1;

            span {
                background-color: #E4EEF7;
                border-radius: 30px;
                padding: 10px 15px;
                font-size: 14px;
                color: #7c7c7c;
                margin-left: 15px / 2;
                margin-right: 15px / 2;
                margin-bottom: 15px;
            }
        }

        h5 {
            font-size: 20px;
            font-weight: 400;
            font-weight: 600;
            margin-bottom: 15px;
            color: #2E2F3A;
        }

        span.description {
            height: 75px;
            overflow: hidden;
            padding-bottom: 15px;
            display: block;
            white-space: wrap;
            text-overflow: ellipsis;
            width: 100%;

            ::v-deep {
                *:last-child {
                    margin-bottom: 0px;
                }
            }
        }

        ::v-deep {
            .buttons,
            .button-group {
                width: 100%;
                padding-right: 0px !important;
                display: flex !important;
                justify-content: center;

                .button {
                    width: 70%;

                    @media (max-width: 767.98px) {
                        width: 100%;
                    }

                    a {
                        width: 100%;
                        background: linear-gradient(85deg, #00A983 5.12%, #2BD784 96.48%) !important;
                        transition: background-color 300ms ease-out !important;
                        cursor: pointer !important;
                    }
                }
            }
        }
    }
</style>